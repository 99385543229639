// * For this page, we want to use static sizing since the content of this page will be printed onto standard sized paper
.route-manifest-container {
  display: flex;
  gap: 7px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background: white;
  flex-direction: column;
  align-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  break-after: page;
}

.route-manifest-nodata-container {
  display: flex;
  gap: 7px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background: white;
  flex-direction: column;
  align-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  break-after: page;
}
.manifest-header-container {
  width: 780px;
  padding: 5px;
  border: 2px black solid;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 130px;
  justify-content: stretch;
  align-content: stretch;
  overflow: auto;
}
.manifest-thd-logo-container {
  width: 40px;
  height: 40px;
  padding-left: 3px;
  display: flex;
  justify-content: left;
}
.manifest-header-text {
  text-align: center;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
  padding: 8px;
}
.manifest-header-print-heading {
  width: 720px;
  height: 25px;
  display: flex;
  flex-direction: column;
}
.manifest-header-detail-label {
  color: #1f1f1f;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  word-wrap: break-word;
}
.manifest-header-detail-text {
  color: #1f1f1f;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
}
.manifest-header-horizontal-bar {
  height: 0px;
  display: flex;
  border: 1px #d9d9d9 solid;
  margin-left: 10px;
}
.manifest-header-vertical-bar {
  width: 0px;
  display: flex;
  justify-content: right;
  border: 1px #d9d9d9 solid;
}
.manifest-header-details {
  width: 780px;
  height: 55px;
  padding-top: 3px;
  display: flex;
}
.manifest-header-route-details {
  width: 480px;
  padding-left: 10px;
  display: flex;
  justify-content: right;
  flex-direction: column;
}
.manifest-header-driver-details {
  width: 250px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 70px;
  justify-content: stretch;
  align-content: stretch;
  overflow: auto;
}
.manifest-stop-container {
  display: flex;
  width: 780px;
  border: 2px black solid;
  justify-content: stretch;
  align-content: stretch;
  flex-direction: row;
  gap: 5px;
  padding-top: 5px;
  overflow: auto;
}
.manifest-stop-order {
  width: 180px;
  padding-left: 5px;
}
.manifest-stop-details {
  width: 180px;
}
.manifest-stop-number {
  width: 180px;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  color: #1f1f1f;
  font-size: 14px;
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  line-height: 25px;
  padding-left: 10px;
  word-wrap: break-word;
}
.manifest-stop-time {
  width: 180px;
  padding-top: 10px;
  color: #1f1f1f;
  font-size: 11px;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 14px;
  word-wrap: break-word;
  padding-left: 10px;
}
.manifest-stop-number-bg {
  width: 130px;
  height: 30px;
  background: #f2f2f2;
  justify-content: center;
  padding-left: -10px;
}

.manifest-order-details {
  width: 180px;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
}
.manifest-item-details-container {
  width: 400px;
  justify-content: stretch;
  align-content: stretch;
  flex-direction: row;
  display: contents;
  flex-wrap: nowrap;
}
.manifest-stop-horizontal-bar {
  width: 170px;
  height: 0px;
  display: flex;
  border: 1px #d9d9d9 solid;
  margin-left: 5px;
}
.manifest-stop-vertical-bar {
  width: 0px;
  display: flex;
  border: 1px #d9d9d9 solid;
  margin-bottom: 10px;
}
.manifest-stop-customer-details {
  color: #1f1f1f;
  font-size: 11px;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 14px;
  word-wrap: break-word;
  padding-left: 10px;
  padding-top: 15px;
  word-wrap: break-word;
  min-height: 100px;
}
.manifest-stop-service-details {
  color: #1f1f1f;
  font-size: 11px;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 14px;
  padding: 10px;
  word-wrap: break-word;
}
.manifest-stop-service-item {
  color: #1f1f1f;
  padding-bottom: 5px;
  word-wrap: break-word;
}
.manifest-stop-service-details-bold {
  font-weight: 600;
  padding-bottom: 5px;
}
.manifest-stop-order-details {
  width: 180px;
  padding-top: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 5px;
  gap: 5px;
  display: inline-flex;
  flex-direction: column;
  padding-bottom: 10px;
  word-wrap: break-word;
  min-height: 10px;
}
.manifest-stop-order-details-label-text {
  color: #1f1f1f;
  font-size: 12px;
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
}
.manifest-stop-order-details-text {
  color: #1f1f1f;
  font-size: 10px;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
}
.manifest-item-details {
  padding-top: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 5px;
  gap: 5px;
  display: inline-flex;
  padding-left: 15px;
}
.manifest-item-details-table {
  width: 380px;
  padding-left: 7px;
  padding-bottom: 10px;
}
.manifest-item-details-table-end {
  width: 1px;
}
.custom-table {
  width: 380px;
  border-collapse: collapse;
  background-color: white;
}
.custom-table .ant-table-tbody > tr > td:first-child,
.custom-table .ant-table-thead > tr > th:first-child {
  text-align: center;
}
.custom-table .ant-table-tbody > tr > td:not(:first-child),
.custom-table .ant-table-thead > tr > th:not(:first-child) {
  padding-left: 10px;
}
.custom-table .ant-table-tbody > tr > td {
  color: #1f1f1f;
  font-size: 10px;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 11px;
  word-wrap: break-word;
  height: 12px;
  padding: 2px;
  align-content: flex-start;
}
.custom-table .ant-table-thead > tr > th {
  color: #1f1f1f;
  font-size: 11px;
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  line-height: 12px;
  word-wrap: break-word;
  height: 16px;
  background-color: white;
  padding: 5px;
}
.print-page-break {
  width: 793px;
  height: 100px;
  padding: 5px;
  display: block;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  background: white;
}
.empty-table-container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manifest-buttons-box {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media print {
  @page {
    size: A4;
    margin: 0px;
    margin-top: 20px;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
  #app {
    background: white;
  }
  .header-container {
    display: none;
  }

  .manifest-buttons-box {
    display: none;
  }
  #routeplanner-view-container {
    padding: 0px 25px 25px 25px;
  }

  #create-it-ticket-container {
    display: none;
  }
  #nebula_div_btn {
    display: none;
  }
  .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
    display: none;
  }
  .print-page-break {
    break-after: page;
  }
  .manifest-stop-container {
    page-break-inside: avoid;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;